import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _get from 'lodash/get';
import { makeSelectAuth, makeSelectProfile } from 'containers/Auth/selectors';
import { makeSelectClientDetails } from 'containers/Main/selectors';
import useIsAuthPath from 'components/Hooks/useIsAuthPath';
import { getSubdomain } from 'utils/stringUtils';

function useHCSCEffect() {
  const history = useHistory();
  const auth = useSelector(makeSelectAuth());
  const profile = useSelector(makeSelectProfile());
  const clientDetails = useSelector(makeSelectClientDetails());
  const isAuthenticationPage = useIsAuthPath();

  const isHCSC =
    _get(clientDetails, 'clientGroup.shortName') === 'HCSC' ||
    _get(clientDetails, 'clientGroup.shortName') === 'HCSC UAT';

  useEffect(() => {
    if (
      isHCSC &&
      _get(clientDetails, 'customAuthentication.enabled') &&
      !isAuthenticationPage &&
      auth.isLoaded &&
      !auth.isEmpty &&
      profile.isLoaded &&
      !profile.isEmpty
    ) {
      if (!profile.email) {
        history.push('/signup/email');
      } else if (
        _get(profile, 'custom.eligibility.client') &&
        !getSubdomain().includes(_get(profile, 'custom.eligibility.client'))
      ) {
        history.push('/auth/sso');
      }
    }
  }, [auth, clientDetails, history, isAuthenticationPage, isHCSC, profile]);
}

export default useHCSCEffect;
