import React, { useEffect } from 'react';

import {
  Box,
  Typography,
  Link as RegularLink,
  makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import Mixpanel from 'utils/mixpanelService';
import useSiteCopySelector from '../../useSiteCopySelector';

const useStyles = makeStyles(theme => ({
  container: {
    marginLeft: 'auto',
    background: '#FFF1EF',
    borderRadius: 100,
    padding: '2px 20px',
  },
  link: {
    color: 'black',
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  number: {
    color: theme.palette.ADACompliantRed,
    fontWeight: 'bold',
    fontSize: 14,
  },
  text: {
    fontSize: 14,
  },
}));

const CrisisBanner = ({ query, fromHeader, onClose }) => {
  const classes = useStyles();

  const [searchConfig] = useSiteCopySelector(['search']);

  const textContent = searchConfig?.pageCopy?.inCrisis;
  const section = fromHeader ? 'search' : 'resources';

  useEffect(() => {
    Mixpanel.track('Get Help Now shown', {
      query,
      section,
    });
  }, []);

  const handleLinkClick = () => {
    Mixpanel.track('Call - Clicked', {
      number: '988',
      section,
    });
  };

  return (
    <Box className={classes.container}>
      <Typography className={classes.text} variant="body2">
        <strong>
          <RegularLink
            onClick={handleLinkClick}
            className={classes.link}
            href="tel:988"
            underline="always"
          >
            {textContent.callLinkText}
          </RegularLink>{' '}
          {textContent.orText} {textContent.textAction}{' '}
        </strong>
        <Typography component="span" className={classes.number}>
          {textContent.phoneNumber}
        </Typography>{' '}
        - {textContent.description}. {textContent.resourcesText}{' '}
        <Link to="/in-crisis" onClick={onClose} className={classes.link}>
          {textContent.hereLinkText}
        </Link>
      </Typography>
    </Box>
  );
};

export default CrisisBanner;
